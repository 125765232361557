export class DisableScroll {
  scrolldisabled = false;
  target = null;
  touchstart = false;
  exception = '';
  constructor(obj) {
    if (obj) {
      if (obj.target !== undefined) this.target = obj.target || null;
      if (typeof this.target === 'string') {
        const targetelem = document.querySelector(this.target);
        this.target = targetelem;
      }
    }
    this.disable();
  }
  disable(object) {
    if (!this.scrolldisabled) {
      console.log('disable');
      this.scrolldisabled = true;
      if (object !== undefined && object.except) {
        this.exception = [...object.except];
      }
      if (this.target === null) {
        if (typeof document !== `undefined`) {
          document.body.classList.add('__disablescroll');
          if ('ontouchstart' in document.documentElement) {
            document.body.classList.add('__disabletouch');
          }
        }
        if (window.addEventListener) {
          window.addEventListener('DOMMouseScroll', this.event.disable, false);
          window.addEventListener('wheel', this.event.disable, {
            passive: false,
          });
          window.addEventListener('mousewheel', this.event.disable, {
            passive: false,
          });
          window.addEventListener('keydown', this.event.keydown, false);
        }
        if ('ontouchstart' in document.documentElement) {
          window.addEventListener('touchstart', this.event.disable, {
            passive: false,
          });
          window.addEventListener('touchmove', this.event.disable, {
            passive: false,
          });
          window.addEventListener('touchend', this.event.disable, {
            passive: false,
          });
          document.addEventListener('touchstart', this.event.disable, {
            passive: false,
          });
          document.addEventListener('touchend', this.event.disable, {
            passive: false,
          });
          document.addEventListener('touchmove', this.event.disable, {
            passive: false,
          });
        }
      } else {
        this.target.classList.add('__disablescroll');
        if (this.target.addEventListener) {
          this.target.addEventListener(
            'DOMMouseScroll',
            this.event.disable,
            false
          );
          this.target.addEventListener('wheel', this.event.disable, {
            passive: false,
          });
          this.target.addEventListener('mousewheel', this.event.disable, {
            passive: false,
          });
          this.target.addEventListener('keydown', this.event.keydown, false);
        }
        if ('ontouchstart' in document.documentElement) {
          this.target.addEventListener('touchstart', this.event.disable, false);
          this.target.addEventListener('touchmove', this.event.disable, false);
          this.target.addEventListener('touchend', this.event.disable, false);
        }
      }
    }
  }
  enable = () => {
    console.log('scroll enable trigger', this.scrolldisabled);
    this.scrolldisabled = false;
    if (this.target === null) {
      if (typeof document !== `undefined`) {
        document.body.classList.remove('__disablescroll');
        document.body.classList.remove('__disabletouch');
      }

      if (window.addEventListener) {
        window.removeEventListener('DOMMouseScroll', this.event.disable, false);
        window.removeEventListener('wheel', this.event.disable, {
          passive: false,
        });
        window.removeEventListener('mousewheel', this.event.disable, {
          passive: false,
        });
        window.removeEventListener('keydown', this.event.keydown, false);
      }
      if ('ontouchstart' in document.documentElement) {
        window.removeEventListener('touchstart', this.event.disable, {
          passive: false,
        });
        window.removeEventListener('touchmove', this.event.disable, {
          passive: false,
        });
        window.removeEventListener('touchend', this.event.disable, {
          passive: false,
        });
        document.removeEventListener('touchstart', this.event.disable, {
          passive: false,
        });
        document.removeEventListener('touchmove', this.event.disable, {
          passive: false,
        });
        document.removeEventListener('touchend', this.event.disable, {
          passive: false,
        });
      }
    } else {
      this.target.classList.remove('__disablescroll');
      if (this.target.addEventListener) {
        this.target.removeEventListener(
          'DOMMouseScroll',
          this.event.disable,
          false
        );
        this.target.removeEventListener('wheel', this.event.disable, {
          passive: false,
        });
        this.target.removeEventListener('mousewheel', this.event.disable, {
          passive: false,
        });
        this.target.removeEventListener('keydown', this.event.keydown, false);
      }
      if ('ontouchstart' in document.documentElement) {
        this.target.removeEventListener(
          'touchstart',
          this.event.disableHard,
          false
        );
        this.target.removeEventListener(
          'touchmove',
          this.event.disableHard,
          false
        );
        this.target.removeEventListener(
          'touchend',
          this.event.disableHard,
          false
        );
      }
    }
  };
  getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return this.getScrollParent(node.parentNode);
    }
  }
  checkException(target) {
    if (this.exception !== '') {
      if (typeof this.exception === 'object') {
        let exceptioncheck = true;
        this.exception.forEach(exception => {
          if (
            this.getScrollParent(target) === document.querySelector(exception)
          ) {
            exceptioncheck = false;
          }
        });
        return exceptioncheck;
      } else {
        return (
          this.getScrollParent(target) !==
          document.querySelector(this.exception)
        );
      }
    }
  }
  event = {
    disable: e => {
      e = e || window.event;
      if (e.type === 'touchstart') {
        this.touchstart = true;
      } else if (e.type === 'touchmove') {
        if (this.touchstart) {
          if (this.checkException(e.target)) {
            if (e.preventDefault) {
              e.preventDefault();
              e.stopPropagation();
            }
            e.returnValue = false;
          }
        }
      } else if (e.type === 'touchend') {
        this.touchstart = false;
      } else {
        if (this.checkException(e.target)) {
          if (e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.returnValue = false;
        }
      }
    },
    disableHard: e => {
      // console.log('touch hard', e);
      if (
        this.getScrollParent(e.target) !==
        document.querySelector(this.exception)
      ) {
        e.returnValue = false;
        e.cancelBubble = true;
        e.preventDefault();
        e.stopPropagation();
      }
    },
    keydown: e => {
      if (e.which === 38 || e.which === 40 || e.which === 32) {
        e = e || window.event;
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }
    },
  };
}
