import React, { useContext, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import OverlayScrollbars from 'overlayscrollbars';

import 'stylesheet/main.scss';
import { GlobalContext } from '../context/GlobalContext';

//UTILS
import { globalVar, checkFile } from 'utils/common';
import { DisableScroll } from 'utils/disablescroll';
import { MediaCheck } from 'utils/mediacheck';
import { LoaderClass } from 'utils/loader';

const Layout = (props) => {
  const context = useContext(GlobalContext);

  useEffect(() => {
    let mainLoader, layoutPostLoad;
    mainLoader = new LoaderClass({
      parent: `#${props.mainID}`,
      firstload_delay: 1000,
      default_delay: 500,
      checkimage: false,
      postload: () => {
        if (typeof window !== 'undefined') {
          context.disableScrollBody.enable();
          //REMOVE TRANSITION CLASS ADDED FROM NAVIGATION
          document.body.classList.remove('transitioning');
          document.body.classList.remove('preloading');
          document.body.classList.add('loaded');
          context.enableScrollToggle = true;
        }
        if (layoutPostLoad && typeof layoutPostLoad === 'function') {
          layoutPostLoad();
        }
      },
    });

    if (context.disableScrollBody === null && typeof window !== 'undefined')
      context.disableScrollBody = new DisableScroll();

    if (props.postLoad && typeof props.postLoad === 'function') {
      layoutPostLoad = props.postLoad;
    } else {
      layoutPostLoad = () => {};
    }
    if (!context.webinit) {
      if (typeof document !== `undefined`) {
        document.body.style.color = context.main_color;
        document.body.style.background = context.main_color;
      }
    }
    mainLoader.renderload();

    if (!context.webinit) {
      //FIRST INIT
      context.webinit = true;
      if (typeof window !== `undefined`) {
        context.disableScrollBody.disable();
      }
      setTimeout(() => {
        mainLoader.mountload();
        if (typeof window !== `undefined`) {
          document.body.classList.add('preloading');
        }
      }, 500);
    } else {
      mainLoader.mountload();
      if (typeof window !== 'undefined') {
        context.disableScrollBody.disable();
      }
    }

    if (typeof window !== 'undefined') {
      const mainLogo = document.querySelector('#mainlogo');
      mainLogo.style.height = '';
    }

    const onpageButtons = document.querySelectorAll('a.onpage');
    if (onpageButtons.length > 0) {
      onpageButtons.forEach((button) => {
        button.addEventListener(
          'click',
          () => {
            if (MediaCheck.width.mobile()) {
              context.toggleMobileMenu(false);
            }
          },
          false
        );
      });
    }
    let currentOS = null;
    if (typeof window !== `undefined`) {
      if (MediaCheck.touch()) {
        if (currentOS !== null) currentOS.destroy();
        currentOS = null;
      } else {
        if (currentOS === null) {
          currentOS = OverlayScrollbars(
            document.querySelector(`.os_scroll_wrapper.${props.mainID} `),
            {
              overflowBehavior: {
                x: 'hidden',
                y: 's'
              },
              callbacks: {
                onScroll: (e) => {
                  const event = new CustomEvent('OverlayScroll', {
                    detail: {
                      scrollposition: e.target.scrollTop,
                      target: e.target,
                    },
                  });
                  window.dispatchEvent(event);
                },
              },
              paddingAbsolute      : true,
              scrollbars : {
                clickScrolling : true,
                autoHide: 's',
              }
            }
          );
        }
      }
    }
    return () => {};
  }, []);
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const setting = data.setting.frontmatter;
        const webname = setting.web_name;

        const weburl = setting.web_url.endsWith('/')
          ? setting.web_url.slice(0, -1)
          : setting.web_url;

        let _color = setting.main_color;

        if (_color < 7 || !_color.startsWith('#')) {
          _color = `#${_color.a}`;
        } else if ((_color === 7 && !_color.startsWith('#')) || _color > 7) {
          _color = context.main_color;
        }

        context.main_color = _color;
        globalVar.main_color = _color;

        context.weburl = weburl;
        context.webname = webname;

        let seo_image = '';
        if (setting.seo.seo_image) {
          seo_image = `${weburl}${checkFile(setting.seo.seo_image.publicURL)}`;
        }
        const seo = {
          desc: setting.seo.seo_shortdesc,
          keywords: setting.seo.seo_keywords,
          image: seo_image,
          url: weburl,
        };
        return (
          <GlobalContext.Consumer>
            {(globalcontext) => (
              <div
                className={`os_scroll_wrapper ${props.mainID} ${props.osClassName}`}
              >
                <main id={props.mainID} className={props.className}>
                  <Helmet>
                    <title>
                      {props.titleText
                        ? `${props.titleText} | ${webname}`
                        : webname}
                    </title>
                    <meta name='description' content={seo.desc} />
                    <meta name='image' content={seo.image} />
                    <meta name='keywords' content={seo.keywords} />
                    {seo.url && <meta property='og:url' content={seo.url} />}

                    {props.titleText ? (
                      <meta
                        property='og:title'
                        content={`${props.titleText} | ${webname}`}
                      />
                    ) : (
                      <meta property='og:title' content={webname} />
                    )}
                    {seo.desc && (
                      <meta property='og:description' content={seo.desc} />
                    )}
                    {seo.image && (
                      <meta property='og:image' content={seo.image} />
                    )}
                    <meta name='twitter:card' content='summary_large_image' />

                    {props.titleText ? (
                      <meta
                        property='twitter:title'
                        content={`${props.titleText} | ${webname}`}
                      />
                    ) : (
                      <meta property='twitter:title' content={webname} />
                    )}
                    {seo.desc && (
                      <meta name='twitter:description' content={seo.desc} />
                    )}
                    {seo.image && (
                      <meta name='twitter:image' content={seo.image} />
                    )}

                    <link
                      rel='mask-icon'
                      href='/safari-pinned-tab.svg'
                      color={context.main_color}
                    />
                    <meta
                      name='msapplication-TileColor'
                      content={context.main_color}
                    />
                    <meta name='theme-color' content={context.main_color} />
                  </Helmet>
                  {props.children}
                </main>
              </div>
            )}
          </GlobalContext.Consumer>
        );
      }}
    />
  );
};

export default Layout;

const query = graphql`
  query {
    setting: markdownRemark(
      frontmatter: {
        issetting: { eq: true }
        contenttype: { eq: "general_setting" }
      }
    ) {
      html
      frontmatter {
        web_name
        web_url
        main_color
        seo {
          seo_keywords
          seo_shortdesc
          seo_image {
            publicURL
          }
        }
      }
    }
  }
`;
