export let firstload = false;
export class LoaderClass {
  loadcheck = {
    time: false,
    data: false,
    page: false,
    reload: false,
    images: false,
  };
  delay = {
    firstload: 0,
    default: 250,
    reload: 250,
  };
  timeout = {
    time: null,
  };
  images = {
    check: true,
    init: false,
    list: null,
    loaded: 0,
  };
  constructor(obj) {
    this.loadcheck.time = false;
    this.loadcheck.data = false;
    this.loadcheck.page = false;
    this.loadcheck.reload = false;
    this.loadcheck.images = false;

    this.images.check = obj.checkimage !== undefined ? obj.checkimage : true;

    this.parent = obj.parent !== null ? obj.parent : null;

    if (
      obj.intervalcheck !== null &&
      obj.intervalcheck !== undefined &&
      typeof obj.intervalcheck === 'function'
    )
      this.intervalcheck = obj.intervalcheck_function;
    if (
      obj.postload !== null &&
      obj.postload !== undefined &&
      typeof obj.postload === 'function'
    )
      this.postload = obj.postload;

    if (obj.firstload_delay) this.delay.firstload = obj.firstload_delay;
    if (obj.default_delay) this.delay.default = obj.default_delay;
    if (obj.reload_delay) this.delay.reload = obj.reload_delay;

    if (!this.images.check) this.loadcheck.images = true;
  }
  intervalcheck() {}
  postload() {}
  reset() {
    this.loadcheck.page = false;
  }
  load() {
    this.intervalcheck();
    if (
      this.loadcheck.time &&
      this.loadcheck.data &&
      this.loadcheck.reload &&
      this.loadcheck.images &&
      !this.loadcheck.page
    ) {
      if (this.delay.firstload > 0) {
        if (firstload) {
          this.loadcheck.page = true;
          firstload = true;
          setTimeout(this.loadfinish(), this.delay.firstload);
        } else {
          this.loadcheck.page = true;

          this.loadfinish();
        }
      } else {
        this.loadcheck.page = true;

        this.loadfinish();
      }
    }
  }
  loadfinish() {
    if (
      this.loadcheck.time &&
      this.loadcheck.data &&
      this.loadcheck.reload &&
      this.loadcheck.images &&
      this.loadcheck.page
    ) {
      this.postload();
    }
  }
  mountload() {
    this.loadcheck.data = true;

    this.load();
    if (!this.images.init && this.images.check) {
      this.loadcheck.images = false;
      this.images.init = true;
      if (this.parent !== null) {
        if (document.querySelector(this.parent))
          this.images.list = document
            .querySelector(this.parent)
            .querySelectorAll('img');
      } else {
        if (document.querySelectorAll('img'))
          this.images.list = document.querySelectorAll('img');
      }
      this.imageload();
    }
  }
  imageload() {
    if (this.images.check) {
      if (this.images.list && this.images.list.length > 0) {
        this.images.list.forEach(image => {
          //ADD CONDITIONAL TO CHECK IF IMAGE HAS VALID SRC
          if (
            image.src !== null &&
            image.src !== '' &&
            image.src !== undefined
          ) {
            const eachimageloaded = e => {
              e.target.removeEventListener('load', eachimageloaded, false);
              e.target.removeEventListener('error', eachimageerror, false);
              this.images.loaded++;
              this.imageloadfinish();
            };
            const eachimageerror = e => {
              e.target.removeEventListener('load', eachimageloaded, false);
              e.target.removeEventListener('error', eachimageerror, false);
              this.images.loaded++;
              console.log('fail to load', e.target);
              this.imageloadfinish();
            };
            if (image.complete) {
              this.images.loaded++;
              this.imageloadfinish();
            } else {
              image.addEventListener('load', eachimageloaded, false);
              image.addEventListener('error', eachimageerror, false);
            }
          } else {
            this.images.loaded++;
            this.imageloadfinish();
          }
        }, this);
      } else {
        this.loadcheck.images = true;
        this.images.init = false;
        this.load();
      }
    }
  }
  imageloadfinish() {
    if (this.images.loaded >= this.images.list.length) {
      this.loadcheck.images = true;
      this.images.init = false;
      this.load();
    }
  }
  renderload() {
    if (firstload) {
      if (!this.loadcheck.time) {
        if (this.timeout.time != null) clearTimeout(this.timeout.time);
        if (this.delay.default > 0) {
          this.timeout.time = setTimeout(() => {
            this.loadcheck.time = true;
            this.loadcheck.reload = true;

            this.load();
          }, this.delay.default);
        } else {
          this.loadcheck.time = true;
          this.loadcheck.reload = true;

          this.load();
        }
      } else {
        if (this.timeout.time != null) clearTimeout(this.timeout.time);

        if (this.delay.reload > 0) {
          this.timeout.time = setTimeout(() => {
            this.loadcheck.reload = true;

            this.load();
          }, this.delay.reload);
        } else {
          this.loadcheck.reload = true;
          this.load();
        }
      }
    } else {
      if (this.timeout.time != null) clearTimeout(this.timeout.time);
      if (this.delay.firstload > 0) {
        this.timeout.time = setTimeout(() => {
          this.loadcheck.time = true;
          this.loadcheck.reload = true;

          this.load();
          firstload = true;
        }, this.delay.firstload);
      } else {
        this.loadcheck.time = true;
        this.loadcheck.reload = true;

        this.load();
        firstload = true;
      }
    }
  }
}

export class ImageLoadClass {
  images = {
    parent: '',
    selector: '',
    init: false,
    list: null,
    loaded: 0,
    delay: 0,
  };
  constructor(obj) {
    this.set(obj);
  }
  post() {}
  posteachimage(image) {}
  set(obj) {
    if (typeof obj === 'object') {
      this.images.parent = obj.parent !== undefined ? obj.parent : '';
      this.images.selector =
        obj.image_selector !== undefined ? obj.image_selector : '';
      this.images.delay = obj.delay !== undefined ? obj.delay : '';
      if (
        obj.postload !== null &&
        obj.postload !== undefined &&
        typeof obj.postload === 'function'
      )
        this.postload = obj.postload;
      if (
        obj.post_imageload !== null &&
        obj.post_imageload !== undefined &&
        typeof obj.post_imageload === 'function'
      )
        this.posteachimage = obj.post_imageload;
    }

    this.images.init = false;
    this.images.loaded = 0;
    let imagesSelector = 'img';
    if (this.images.selector !== '') {
      imagesSelector = this.images.selector;
    }
    if (this.images.parent === '') {
      this.images.list = document.querySelectorAll(imagesSelector);
    } else {
      if (typeof this.images.parent === 'string') {
        this.images.list = document
          .querySelector(this.images.parent)
          .querySelectorAll('img');
      } else {
        this.images.list = this.images.parent.querySelectorAll(imagesSelector);
      }
    }

    if (this.images.list && this.images.list.length > 0) {
      this.images.init = true;
      this.images.list.forEach(image => {
        //ADD CONDITIONAL TO CHECK IF IMAGE HAS VALID SRC
        if (image.src !== null && image.src !== '' && image.src !== undefined) {
          if (image.complete) {
            this.images.loaded++;
            this.posteachimage(image);
            this.imageloadfinish();
          } else {
            image.addEventListener('load', this.eachimageloaded, false);
            image.addEventListener('error', this.eachimageerror, false);
          }
        } else {
          this.images.loaded++;
          this.posteachimage(image);
          this.imageloadfinish();
        }
      }, this);
    } else {
      this.imageloadfinish();
    }
  }
  eachimageloaded = e => {
    e.target.removeEventListener('load', this.eachimageloaded, false);
    e.target.removeEventListener('error', this.eachimageerror, false);
    this.images.loaded++;
    this.posteachimage(e.target);
    this.imageloadfinish();
  };
  eachimageerror = e => {
    e.target.removeEventListener('load', this.eachimageloaded, false);
    e.target.removeEventListener('error', this.eachimageerror, false);
    this.images.loaded++;
    this.posteachimage(e.target);
    console.log('fail to load', e.target);
    this.imageloadfinish();
  };
  imageloadfinish() {
    if (this.images.loaded >= this.images.list.length) {
      this.images.init = false;
      if (this.images.delay > 0) {
        setTimeout(() => {
          this.postload();
        }, this.images.delay);
      } else {
        this.postload();
      }
    }
  }
  kill() {
    if (this.images.list && this.images.list.length > 0) {
      this.images.list.forEach(image => {
        //ADD CONDITIONAL TO CHECK IF IMAGE HAS VALID SRC
        if (image.src !== null && image.src !== '' && image.src !== undefined) {
          image.removeEventListener('load', this.eachimageloaded, false);
          image.removeEventListener('error', this.eachimageerror, false);
        }
      }, this);
    }
  }
}
