import React from 'react';
// import { graphql } from 'gatsby';
import { GlobalContext } from '../context/GlobalContext';
import Layout from 'components/layout';
import 'stylesheet/error.scss';

const Error404 = ({ location }) => (
  <GlobalContext.Consumer>
    {context => (
      <Layout
        titleText={`404`}
        mainID={'Error404'}
        location={location}
        style={{ color: context.main_color }}
      >
        <span>FOUR</span>
        <span>OH!</span>
        <span>FOUR</span>
      </Layout>
    )}
  </GlobalContext.Consumer>
);

export default Error404;